import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// icons
import PeopleIcon from "@material-ui/icons/People";
import HomeIcon from "@material-ui/icons/Home";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import ReportIcon from '@material-ui/icons/Report';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { NavLink as RouterLink } from "react-router-dom";
import ProtectedContent from "../protectedContent/ProtectedContent";

interface Props {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 240,
    },
    toolbar: theme.mixins.toolbar,
    logo: {
      width: "80%",
      marginTop: "18px",
      marginBottom: "18px",
      marginLeft: "50%",
      transform: "translateX(-50%)"
    },
    link: {
      color: "#fff",
      width: "calc(100% + 10px)",
      "&:hover, &:focus": {
        backgroundColor: "rgba(12,39,47,0.6)",
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
      },
    },
    activeLink: {
      backgroundColor: "rgba(12,39,47,0.4)",
      width: "calc(100% + 10px)",
      "&:hover, &:focus": {
        backgroundColor: "rgba(12,39,47,0.4)",
      },
    },
  })
);

const Navigation: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => props.toggleDrawer(true)}
      onKeyDown={() => props.toggleDrawer(false)}
    >
      <div className={classes.toolbar}>
        <RouterLink to="/">
          <img src="/assets/img/logo.svg" className={classes.logo} />
        </RouterLink>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to="/"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ProtectedContent>
          <ListItem
            button
            component={RouterLink}
            to="/topic-group"
            exact
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <CloudQueueIcon />
            </ListItemIcon>
            <ListItemText primary={"Topic Group"} />
          </ListItem>
        </ProtectedContent>
        <ProtectedContent>
          <ListItem
            button
            component={RouterLink}
            to="/topic"
            exact
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <CloudQueueIcon />
            </ListItemIcon>
            <ListItemText primary={"Topic"} />
          </ListItem>
        </ProtectedContent>
        <ListItem
          button
          component={RouterLink}
          to="/report"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <ReportIcon />
          </ListItemIcon>
          <ListItemText primary={"Report"} />
        </ListItem>
        <ProtectedContent>
          <ListItem
            button
            component={RouterLink}
            to="/user-management"
            className={classes.link}
            activeClassName={classes.activeLink}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={"User managment"} />
          </ListItem>
        </ProtectedContent>
        <ListItem
          button
          component={RouterLink}
          to="/general-notification"
          exact
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary={"General Notification"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          component={RouterLink}
          to="/support"
          className={classes.link}
          activeClassName={classes.activeLink}
        >
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary={"Support"} />
        </ListItem>
      </List>
    </div>
  );
};

export default Navigation;
