import * as React from "react";
import * as ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import App from "./containers/App";
import { configAxios } from "./util/axios";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./features/root-reducer";
import { initialState } from "./interfaces/App-State";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

const w: any = window as any;
const devtools: any = w.__REDUX_DEVTOOLS_EXTENSION__
    ? w.__REDUX_DEVTOOLS_EXTENSION__()
    : (f: any) => f;

const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(thunk), devtools)
);

configAxios();

ReactDOM.render(
    <Provider store={store}>
        <Router history={createBrowserHistory()}>
            <App />
        </Router >
    </Provider>,
    document.getElementById("root")
); 