import { createMuiTheme, createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { bindActionCreators } from "redux";
import Header, { drawerWidth } from "../components/header/Header";
import Routes from "../routes";
import { useThunkDispatch } from "../util/thunk-dispatch";
import { getCurrentUser } from "../features/user-feature";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: "block",
            flexGrow: 1,
            [theme.breakpoints.up("lg")]: {
                width: `calc(100vw - ${drawerWidth + 115}px)`,
                marginLeft: drawerWidth,
                padding: "45px 0 45px 45px"
            },
            marginTop: "64px",
            padding: "45px 45px 45px 45px"
        },
        toolbar: theme.mixins.toolbar,
    })
);

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#ababab',
            dark: '#009FAB',
            main: '#009FAB',
            contrastText: '#fff',
        },
        secondary: {
            main: '#FBE97F',
            dark: '#FBE97F',
        },
    }
});

const App: React.FC = () => {
    const classes = useStyles();
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators(
        { getCurrentUser },
        asyncDispatch
    );

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Header />
                <main className={classes.content}>
                    <Routes />
                </main>
            </ThemeProvider>
        </>
    );
}

export default App;