import axios from "axios";
import { GetUsersDto } from "../interfaces/dto/GetUsersDto";
import { User } from "../interfaces/User";

export const getCurrent = () => {
    return axios.get("/users/getCurrent");
}

export const getAll = (getUsersDto: GetUsersDto) => {
    return axios.post("/users/getAll", getUsersDto);
}

export const getById = (userId: string) => {
    return axios.get("/users/getById", { params: { userId } })
}

export const add = (user: User) => {
    return axios.post("/users/add", user);
}

export const updateRole = (user: User) => {
    return axios.put("/users/updateRole", user);
}

export const setEmailConfirmed = (userId: string) => {
    return axios.put(`/users/setEmailConfirmed?userId=${userId}`);
}