import * as React from "react";
import { Switch, Route } from "react-router-dom";
import * as loadable from 'react-loadable';
import Loading from "./components/loading/Loading";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import { useThunkDispatch } from "./util/thunk-dispatch";
import { bindActionCreators } from "redux";
import { useSelector } from "react-redux";
import { AppState } from "./interfaces/App-State";
import { User } from "./interfaces/User";
import { Request } from "./interfaces/Request";
import { getCurrentUser } from "./features/user-feature";

const AsyncLoginContainer = loadable({ loader: () => import(/* webpackChunkName: "Login" */ "./containers/authentication/Login"), loading: Loading });
const AsyncLogoutContainer = loadable({ loader: () => import(/* webpackChunkName: "Logout" */ "./containers/authentication/Logout"), loading: Loading });

const AsyncDashboardContainer = loadable({ loader: () => import(/* webpackChunkName: "Dashboard" */ "./containers/dashboard/Dashboard"), loading: Loading });
const AsyncUserManagmentContainer = loadable({ loader: () => import(/* webpackChunkName: "UserManagement" */ "./containers/userManagement/UserManagement"), loading: Loading });
const AsyncUserEditContainer = loadable({ loader: () => import(/* webpackChunkName: "UserEdit" */ "./containers/userManagement/UserEdit"), loading: Loading });
const AsyncTopicGroupContainer = loadable({ loader: () => import(/* webpackChunkName: "TopicGroup" */ "./containers/topicGroup/TopicGroup"), loading: Loading });
const AsyncTopicGroupEditContainer = loadable({ loader: () => import(/* webpackChunkName: "TopicGroupEdit" */ "./containers/topicGroup/TopicGroupEdit"), loading: Loading });
const AsyncTopicGroupAddContainer = loadable({ loader: () => import(/* webpackChunkName: "TopicGroupAdd" */ "./containers/topicGroup/TopicGroupAdd"), loading: Loading });
const AsyncTopicContainer = loadable({ loader: () => import(/* webpackChunkName: "Topic" */ "./containers/topic/Topic"), loading: Loading });
const AsyncTopicEditContainer = loadable({ loader: () => import(/* webpackChunkName: "TopicEdit" */ "./containers/topic/TopicEdit"), loading: Loading });
const AsyncTopicAddContainer = loadable({ loader: () => import(/* webpackChunkName: "TopicAdd" */ "./containers/topic/TopicAdd"), loading: Loading });
const AsyncReportContainer = loadable({ loader: () => import(/* webpackChunkName: "Report" */ "./containers/report/Report"), loading: Loading });
const AsyncReportEditContainer = loadable({ loader: () => import(/* webpackChunkName: "ReportEdit" */ "./containers/report/ReportEdit"), loading: Loading });
const AsyncSupportContainer = loadable({ loader: () => import(/* webpackChunkName: "Support" */ "./containers/support/Support"), loading: Loading });
const AsyncGeneralNotificationContainer = loadable({ loader: () => import(/* webpackChunkName: "GeneralNotification"  */ "./containers/generalNotification/GeneralNotification"), loading: Loading });
const AsyncGeneralNotificationAddContainer = loadable({ loader: () => import(/* webpackChunkName: "GeneralNotificationAdd" */ "./containers/generalNotification/GeneralNotificationAdd"), loading: Loading });
const AsyncGeneralNotificationEditContainer = loadable({ loader: () => import(/* webpackChunkName: "GeneralNotificationEdit" */ "./containers/generalNotification/GeneralNotificationEdit"), loading: Loading });

const Routes: React.FC = () => {
    const asyncDispatch = useThunkDispatch();
    const actions = bindActionCreators({ getCurrentUser }, asyncDispatch);
    const requests = useSelector<AppState, Request[]>(state => state.requests);
    const [userRequestStatus, setUserRequestStatus] = React.useState("REQUEST");
    const user = useSelector<AppState, User>((state) => state.user);

    React.useEffect(() => {
        actions.getCurrentUser();
    }, []);

    React.useEffect(() => {
        var userRequestString = requests.filter(x => x.type === "user/GET_CURRENT").length > 0 ? requests.filter(x => x.type === "user/GET_CURRENT")[0].status : "REQUEST";
        setUserRequestStatus(userRequestString);
    }, [requests]);

    return (
        <Switch>
            <Route exact path="/login/:url?/:key?" render={() => <AsyncLoginContainer />} />
            <Route exact path="/logout" render={() => <AsyncLogoutContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/user-management" render={() => <AsyncUserManagmentContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"  ||  user.roleName?.toLowerCase() === "moderator"} exact path="/user-edit/:id" render={() => <AsyncUserEditContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic-group" render={() => <AsyncTopicGroupContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic-group-add/" render={() => <AsyncTopicGroupAddContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic-group-edit/:id" render={() => <AsyncTopicGroupEditContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic" render={() => <AsyncTopicContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic-add" render={() => <AsyncTopicAddContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" && user.roleName?.toLowerCase() === "administrator"} exact path="/topic-edit/:id" render={() => <AsyncTopicEditContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" &&( user.roleName?.toLowerCase() === "administrator" ||  user.roleName?.toLowerCase() === "moderator")} exact path="/report" render={() => <AsyncReportContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" &&( user.roleName?.toLowerCase() === "administrator" ||  user.roleName?.toLowerCase() === "moderator")} exact path="/report-edit/:type/:id" render={() => <AsyncReportEditContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" &&( user.roleName?.toLowerCase() === "administrator" ||  user.roleName?.toLowerCase() === "moderator")} exact path="/general-notification" render={() => <AsyncGeneralNotificationContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" &&( user.roleName?.toLowerCase() === "administrator" ||  user.roleName?.toLowerCase() === "moderator")} exact path="/general-notification-add" render={() => <AsyncGeneralNotificationAddContainer />} />
            <ProtectedRoute isAdminOnly isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != "" &&( user.roleName?.toLowerCase() === "administrator" ||  user.roleName?.toLowerCase() === "moderator")} exact path="/general-notification-edit/:id" render={() => <AsyncGeneralNotificationEditContainer />} />

            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/" render={() => <AsyncDashboardContainer />} />
            <ProtectedRoute isLoading={userRequestStatus === "REQUEST"} authed={user && user.id != ""} exact path="/support" render={() => <AsyncSupportContainer />} />
        </Switch>
    );
}

export default Routes;