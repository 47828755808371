
import { Role } from "./Role";

export interface User {
    [key: string]: any
    id: string,
    userName: string,
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    roleName: string,
    password: string,
    confirmPassword: string,
    cautioned: boolean,
    banned: boolean,
    active: boolean,
    emailConfirmed: boolean
}

export const defaultUser: User = {
    id: "",
    userName: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    roleName: "",
    password: "",
    confirmPassword: "",
    cautioned: false,
    banned: false,
    active: true,
    emailConfirmed: false
}