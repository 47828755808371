import * as React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorIconWrapper: {
        textAlign: "center",
        marginTop: "75px"
    },
    errorIcon: {
      width: "300px",
    }
  })
);

const NotAuthorized: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.errorIconWrapper}>
      <img
        src="/assets/img/error.svg"
        alt="Error"
        className={classes.errorIcon}
      />
      <Typography variant="h4"><strong>403</strong> - No access.</Typography>
      <Typography variant="body1">You don't have any permission to access this ressource.</Typography>
      <Box marginTop={2} />
      <Button variant="contained" color="primary" component={RouterLink} to="/">Back</Button>
    </div>
  );
};

export default NotAuthorized;
