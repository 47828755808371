import { User } from "../interfaces/User";

export const isAdministrator = (currentUser: User) => {
    return currentUser.roleName && currentUser.roleName.toLowerCase() === "administrator";
}

export const isUniversityMember = (currentUser: User) => {
    return currentUser.roleName && currentUser.roleName.toLowerCase() === "university";
}

export const isLaborMember = (currentUser: User) => {
    return currentUser.roleName && currentUser.roleName.toLowerCase() === "labor";
}